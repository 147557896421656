import { CloseOutlined } from "@ant-design/icons";
import { message, Progress, Spin, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { uploadFileApi } from "@/services/api";
import { IOSSUploadRequest } from "@/types/oss";
import { judgmentType } from "@/utils/other";

import { Icon } from "../icon";
import styles from "./create-tutorial.module.less";
import { ICoursewareUploadProps, IFileData } from "./types";

export const CoursewareUpload: FC<ICoursewareUploadProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const [fileList, setFileList] = useState<IFileData[]>([]);

  const uploadFileType = useMemo(() => {
    if (fileList.length) {
      return judgmentType(fileList[0].type);
    }

    return "";
  }, [fileList]);

  const [spin, setSpin] = useState(false);

  const [progress, setProgress] = useState(0);

  const postUploadFile = (data: IOSSUploadRequest) => {
    setSpin(true);
    setProgress(0);
    uploadFileApi(data, {
      onUploadProgress(progressEvent) {
        const percent = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );

        setProgress(percent);
      },
    })
      .then((res) => {
        const { file } = data;

        const { path } = res.data;

        const newFileList = [
          ...fileList,
          { uid: file.uid, type: file.type, name: file.name, path },
        ];

        setFileList(newFileList);

        triggerChange(newFileList);
        message.success("Uploading successfully");
      })
      .finally(() => {
        setSpin(false);
      });
  };

  const handleRemoveFile = (file: IFileData) => {
    const index = fileList.indexOf(file);

    const newFileList = fileList.slice();

    newFileList.splice(index, 1);
    setFileList(newFileList);
    triggerChange(newFileList);
  };

  const uploadProps: UploadProps = {
    name: "courseware",
    multiple: false,
    accept: ".mp4,.mov,.jpg,.png,.pdf",
    listType: "picture-card",
    showUploadList: false,
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      const { file } = info;

      const typeRes = judgmentType(file.type || "");

      if (!typeRes) {
        message.error(t("file_type_not_supported"));

        return;
      }
      if (
        fileList.length === 0 ||
        (typeRes === "IMG" && uploadFileType === "IMG")
      ) {
        postUploadFile({ file: file as RcFile });
      } else {
        message.error(t("upload_type_tips"));
      }
    },
  };

  const triggerChange = (changedValue: IFileData[]) => {
    onChange?.(changedValue);
  };

  useEffect(() => {
    value && setFileList(value);
  }, [value]);

  return (
    <div>
      <Spin spinning={spin}>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <Icon icon="upload" style={{ cursor: "pointer" }} />
          </p>
          <p className={styles["upload-text"]}>{t("click_or_drag")}</p>
          <p className={styles["upload-hint"]}>
            {t("extension_support")}: .mp4 .mov .jpg .png .pdf
          </p>
        </Dragger>
        <div>
          {(value || fileList)?.map((file) => {
            return (
              <div key={file.uid} className={styles["file-item"]}>
                <p className={styles["file-name"]}>
                  {file.type === "application/pdf" && <Icon icon="pdf" />}
                  {file.type === "video/mp4" && <Icon icon="video" />}
                  {file.type?.includes("image") && <Icon icon="img" />}
                  <span>{file.name}</span>
                </p>
                <CloseOutlined onClick={() => handleRemoveFile(file)} />
              </div>
            );
          })}
          {spin && <Progress percent={progress} size="small" />}
        </div>
      </Spin>
    </div>
  );
};
