import Cookies from "js-cookie";
import Echo from "laravel-echo";
import io from "socket.io-client";

import { Env } from "@/config/env";
import { TOKEN_IN_COOKIE } from "@/libs/constants";

const ECHO_HOST = Env.ECHO_HOST; // 这里需要从项目配置里读取服务器信息

const JWT_TOKEN = Cookies.get(TOKEN_IN_COOKIE); // 这里需要从本地缓存里读取 api token

const echo = new Echo({
  broadcaster: "socket.io",
  host: ECHO_HOST,
  client: io,
  withCredentials: false,
  transports: ["websocket"],
  auth: {
    headers: {
      Authorization: JWT_TOKEN,
    },
  },
});

export default echo;
