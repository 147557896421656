import { useMount, useRequest } from "ahooks";
import Cookies from "js-cookie";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { Icon } from "@/components/icon";
import { IconTypes } from "@/components/icon/icons";
import {
  INDEX_NAV_WHITE_LIST,
  TOKEN_EXPIRE,
  TOKEN_IN_COOKIE,
} from "@/libs/constants";
import { getStructuresData } from "@/libs/structures";
import { staffState, userPermissionsState } from "@/models";
import { getAuthInfoReq, getPermissionReq } from "@/services/api";
import { saveTokenExpire } from "@/utils/other";
import { localGet } from "@/utils/storage";

import { NavList } from "./components/navigation/nav-list";
import { INavItem } from "./components/navigation/props";
import { QuickGenerateTutorial } from "./components/quick-generate-tutorial/quick-generate-tutorial";
import styles from "./index.module.less";
import { useStore } from "./use-store";

const createNavIcon = (iconKey: IconTypes) => {
  return <Icon icon={iconKey} />;
};

export const IndexPage = () => {
  // eslint-disable-next-line no-empty-pattern
  const {} = useStore();

  const { pathname } = useLocation();

  const location = useLocation();

  const navigate = useNavigate();

  const [permission, setPermission] = useRecoilState(userPermissionsState);

  const [staff, setStaff] = useRecoilState(staffState);

  const handleNavClick = (value: INavItem) => {
    navigate(value.path);
  };

  const { t } = useTranslation();

  const navListData: INavItem[] = useMemo(
    () => [
      {
        label: t("learning_data"),
        path: "/learning-data",
        icon: createNavIcon("learningData"),
        activeIcon: createNavIcon("learningDataActive"),
      },
      {
        label: t("my_course"),
        path: "/my-course",
        icon: createNavIcon("myCourse"),
        activeIcon: createNavIcon("myCourseActive"),
      },
      {
        label: t("my_exam"),
        path: "/my-exams",
        icon: createNavIcon("myExamination"),
        activeIcon: createNavIcon("myExaminationActive"),
      },
      {
        label: t("more_courses"),
        path: "/more-courses",
        icon: createNavIcon("moreCourses"),
        activeIcon: createNavIcon("moreCoursesActive"),
      },
      {
        label: t("exam_review"),
        path: "/review",
        icon: createNavIcon("examinationReview"),
        activeIcon: createNavIcon("examinationReviewActive"),
        isHidden: permission ? !permission.is_grader : true,
      },
      {
        label: t("departmental_course"),
        path: "/department-course",
        icon: createNavIcon("departmentalCourse"),
        activeIcon: createNavIcon("departmentalCourseActive"),
      },
    ],
    [t, permission]
  );

  const getPermissionRequest = useRequest(getPermissionReq, {
    manual: true,
    onSuccess: (result) => {
      const { data } = result;

      setPermission(data);
    },
  });

  const getAuthInfoRequest = useRequest(getAuthInfoReq, {
    manual: true,
    onSuccess: (result) => {
      setStaff(result.data.staff);
    },
  });

  useMount(() => {
    const token = Cookies.get(TOKEN_IN_COOKIE);

    if (token && !staff) {
      getAuthInfoRequest.run();
    }
    saveTokenExpire();
  });

  useEffect(() => {
    if (pathname === "/") {
      navigate(navListData[0].path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const minHeight = window.innerHeight - 80; // 80:头部高度

  useEffect(() => {
    getStructuresData();
    getPermissionRequest.run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="body" style={{ height: minHeight }}>
      {INDEX_NAV_WHITE_LIST.includes(pathname) && (
        <div className={styles["navigation-wrapper"]}>
          <NavList
            navList={navListData}
            activeKey={pathname}
            onNavClick={handleNavClick}
          />
          {permission?.is_tutor_user && <QuickGenerateTutorial />}
        </div>
      )}
      <Outlet />
    </div>
  );
};
export default IndexPage;
