import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const ReviewTestPaper = lazy(() => import("@/pages/review-test-paper"));

export const reviewTestPaper: RouteObject = {
  path: "/reviewTestPaper",
  element: <RouterGuard element={<ReviewTestPaper />} valid={false} />,
};
