import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const CourseDetail = lazy(() => import("@/pages/course-detail"));

const courseDetail: RouteObject = {
  path: "/course-detail/:courseType/:courseId",
  element: <RouterGuard element={<CourseDetail />} />,
  children: [],
};

export default courseDetail;
