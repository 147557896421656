import { message } from "antd";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

import echo from "@/echo";
import { isUnreadMessageState, staffState } from "@/models";

export const useStore = () => {
  const [staff] = useRecoilState(staffState);

  const [, setIsUnreadMessage] = useRecoilState(isUnreadMessageState);

  useEffect(() => {
    const userId = staff?.id; // 当前登录用户的ID

    echo
      .private("Users." + userId)
      .notification(
        (notification: { type: string; data: { message: string } }) => {
          setIsUnreadMessage(true);
          message.success(notification.data.message);
        }
      );
    // 加入公共频道
    echo.join("Users.Public");
  }, [staff, setIsUnreadMessage]);

  return {};
};
