/* eslint-disable camelcase */
import { useRequest } from "ahooks";
import { Form, message } from "antd";
import { Moment } from "moment";
import { useState } from "react";

import { postCreateTutorialApi, putEditTutorialApi } from "@/services/api";
import {
  ICreateTutorialRequest,
  IEditTutorialRequest,
} from "@/types/dept-tutorials";

import { IFormInstance, IGenerateTutorialProps } from "./types";

export const useStore = (props: IGenerateTutorialProps) => {
  const { onClose, defaultData, onChange } = props;

  const [form] = Form.useForm<IFormInstance>();

  const [loading, setLoading] = useState(false);

  const updateLoading = () => {
    setLoading(!loading);
  };

  const postCreateTutorialRequest = useRequest(postCreateTutorialApi, {
    manual: true,
    onSuccess: () => {
      form.resetFields();
      onClose();
      message.open({
        type: "success",
        content: "Success",
      });
    },
    onBefore: updateLoading,
    onFinally: updateLoading,
  });

  const putEditTutorialRequest = useRequest(putEditTutorialApi, {
    manual: true,
    onSuccess: (res, params) => {
      form.resetFields();
      onClose();
      onChange?.(params);
      message.open({
        type: "success",
        content: "Success",
      });
    },
    onBefore: updateLoading,
    onFinally: updateLoading,
  });

  const handleCreateTutorial = (data: ICreateTutorialRequest) => {
    postCreateTutorialRequest.run(data);
  };

  const handleEditTutorial = (id: string, data: IEditTutorialRequest) => {
    putEditTutorialRequest.run(id, data);
  };

  const handleOk = () => {
    form.validateFields().then((value) => {
      const { title, description, shelfTime, personnelScope, courseware } =
        value;

      const [start_time, end_time] = shelfTime.map((time: Moment) => {
        return time.toJSON();
      });

      const scopes = personnelScope.map((item) => item.key);

      const attachmentPaths = courseware
        .filter((item) => !!item.path)
        .map((item) => item.path);

      if (defaultData?.id) {
        handleEditTutorial(defaultData.id, {
          attachments: attachmentPaths,
          description,
          end_time,
          start_time,
          title,
          scopes,
        });
      } else {
        handleCreateTutorial({
          attachments: attachmentPaths,
          description,
          end_time,
          start_time,
          title,
          scopes,
        });
      }
    });
  };

  return {
    form,
    loading,
    handleOk,
  };
};
