import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const MyExams = lazy(() => import("@/pages/exams/my-exams"));

const ViewExams = lazy(() => import("@/pages/exams/view-exams"));

const CheckScore = lazy(() => import("@/pages/exams/check-score"));

const CheckScoreDetail = lazy(() => import("@/pages/exams/check-score-detail"));

const StartExam = lazy(() => import("@/pages/exams/start-exam"));

const exams: RouteObject = {
  path: "/my-exams",
  children: [
    {
      index: true,
      element: <RouterGuard element={<MyExams />} />,
    },
    {
      path: "/my-exams/view-exams",
      element: <RouterGuard element={<ViewExams />} />,
    },
    {
      path: "/my-exams/view-exams/check-score",
      element: <CheckScore />,
    },
    {
      path: "/my-exams/view-exams/check-score/detail",
      element: <CheckScoreDetail />,
    },
    {
      path: "/my-exams/view-exams/start-exams",
      element: <StartExam />,
    },
  ],
};

export default exams;
