import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const DepartmentCourse = lazy(
  () => import("@/pages/department-course/department-course")
);

const MyViewedRecordPage = lazy(
  () => import("@/pages/department-course/my-view-record")
);

const StudentViewedRecordPage = lazy(
  () => import("@/pages/department-course/student-viewed-record")
);

const exams: RouteObject = {
  path: "/department-course",
  children: [
    {
      index: true,
      element: <RouterGuard element={<DepartmentCourse />} />,
    },
    {
      path: "/department-course/my-viewed-record",
      element: <RouterGuard element={<MyViewedRecordPage />} />,
    },
    {
      path: "/department-course/student-viewed-record",
      element: <RouterGuard element={<StudentViewedRecordPage />} />,
    },
  ],
};

export default exams;
