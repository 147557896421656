import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const Login = lazy(() => import("@/pages/login"));

export const login: RouteObject = {
  path: "/login",
  element: <RouterGuard element={<Login />} valid={false} />,
};
