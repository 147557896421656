import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const LearningData = lazy(() => import("@/pages/learning-data"));

const learningData: RouteObject = {
  path: "/learning-data",
  element: <RouterGuard element={<LearningData />} />,
  children: [],
};

export default learningData;
