import { atom } from "recoil";

import { IPersonalPermission, IStaff } from "@/types/auth";

export const staffState = atom<IStaff | null>({
  key: "staffState",
  default: null,
});

export const userPermissionsState = atom<IPersonalPermission | null>({
  key: "userPermissionsState",
  default: null,
});

export const isUnreadMessageState = atom<boolean>({
  key: "isUnreadMessageState",
  default: false,
});
