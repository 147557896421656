import { PlusCircleFilled } from "@ant-design/icons";
import { Tag } from "antd";
import { FC, useEffect, useState } from "react";

import { IStructures } from "@/types/dept-tutorials";

import { PersonnelScopeModal } from "../personnel-scope-modal";
import styles from "./create-tutorial.module.less";
import { IPersonnelScopeInputProps } from "./types";

export const PersonnelScopeInput: FC<IPersonnelScopeInputProps> = ({
  value,
  onChange,
}) => {
  const [scope, setScope] = useState<IStructures[]>([]);

  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const updateScope = (nodes: IStructures[]) => {
    setScope(nodes);
    triggerChange(nodes);
    closeModal();
  };

  const removeScope = (key: string) => {
    const newNode = scope.filter((item) => item.key !== key);

    setScope(newNode);
    triggerChange(newNode);
  };

  const triggerChange = (changedValue: IStructures[]) => {
    onChange?.(changedValue);
  };

  useEffect(() => {
    value && setScope(value);
  }, [value]);

  return (
    <div className={styles["personnel-scope"]}>
      <div className={styles["personnel-scope-input"]}>
        {(value || scope).map((item) => {
          return (
            <Tag
              key={item.key}
              closable
              className={styles["tap-item"]}
              onClose={() => removeScope(item.key)}
            >
              {item.title}
            </Tag>
          );
        })}
      </div>
      <PlusCircleFilled
        style={{ fontSize: "24px", color: "#475aec" }}
        onClick={openModal}
      />
      <PersonnelScopeModal
        defaultCheckedNodes={value || scope}
        open={open}
        onCancel={closeModal}
        onOk={updateScope}
      />
    </div>
  );
};
