import Cookies from "js-cookie";

import { TOKEN_EXPIRE, TOKEN_IN_COOKIE } from "@/libs/constants";

import { localSet } from "./storage";

export const checkFileType = (fileType: string): boolean => {
  const allowedTypes = [
    "video/mp4",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  return allowedTypes.includes(fileType);
};
// 判断上传类型
export const judgmentType = (
  fileType: string
): "" | "IMG" | "VIDEO" | "PDF" => {
  const videoTypes = ["video/mp4", "video/quicktime"];

  const pdfTypes = ["application/pdf"];

  const imageTypes = ["image/jpeg", "image/png"];

  if (videoTypes.includes(fileType)) {
    // 文件类型是mp4
    return "VIDEO";
  } else if (pdfTypes.includes(fileType)) {
    // 文件类型是pdf
    return "PDF";
  } else if (imageTypes.includes(fileType)) {
    // 文件类型是img
    return "IMG";
  } else {
    // 文件类型不在允许的类型列表中
    return "";
  }
};
// 防抖
// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: NodeJS.Timeout | null = null;

  // 添加类型断言，明确返回函数的上下文类型为 'any' 或具体类型（如果需要）
  return function (this: any, ...args: Parameters<T>) {
    // 如果存在定时器，则清除它
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    // 设置新的定时器
    timeoutId = setTimeout(() => {
      // 调用原始函数，并传递所有参数
      func.apply(this, args);
      // 清空定时器ID，防止内存泄漏
      timeoutId = null;
    }, delay);
  } as (...args: Parameters<T>) => void;
};

// 根据token 获取有效期
export const getTokenExp = (token: string) => {
  try {
    const base64Url = token.split(".")[1];

    const base64 = base64Url.replace("-", "+").replace("_", "/");

    const decodedToken = JSON.parse(atob(base64));

    if (decodedToken && decodedToken.exp) {
      return decodedToken.exp * 1000;
    }

    return null;
  } catch (error) {
    return null;
  }
};
export const saveTokenExpire = () => {
  const token = Cookies.get(TOKEN_IN_COOKIE);

  if (token) {
    const tokenInfo = token.split(" ");

    // 解析token，将其分为头部、载荷和签名
    const parts = tokenInfo[1].split(".");

    const payload = JSON.parse(atob(parts[1]));

    if (payload.exp) {
      // 获取有效期信息
      const expire = payload.exp * 1000; // 转换为毫秒级时间戳

      localSet(TOKEN_EXPIRE, expire);
    }
  }
};
