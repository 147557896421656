import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const ExamReview = lazy(() => import("@/pages/review/exam-review"));

const ReviewPaparSubmit = lazy(
  () => import("@/pages/review/review-paper-submit")
);

const ReviewPaparDetail = lazy(
  () => import("@/pages/review/review-paper-detail")
);

const review: RouteObject = {
  path: "/review",
  children: [
    {
      index: true,
      element: <RouterGuard element={<ExamReview />} />,
    },
    {
      path: "/review/review-paper-submit",
      element: <ReviewPaparSubmit />,
    },
    {
      path: "/review/review-paper-detail",
      element: <ReviewPaparDetail />,
    },
  ],
};

export default review;
