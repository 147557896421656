import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { Navigate, RouteProps } from "react-router-dom";

import { TOKEN_IN_COOKIE } from "@/libs/constants";

interface IRouterGuardProps {
  valid?: boolean;
}

// 路由验证
const RouterGuard: React.FC<IRouterGuardProps & RouteProps> = ({
  valid = true,
  element,
}) => {
  if (valid) {
    const token = Cookies.get(TOKEN_IN_COOKIE);

    const pathname = window.location.pathname;

    if (!token) {
      return <Navigate to={`/login?returnUrl=${pathname}`} />;
    }

    return element as React.ReactElement;
  } else {
    return element as React.ReactElement;
  }
};

RouterGuard.propTypes = {
  valid: PropTypes.bool,
  element: PropTypes.node,
};
export { RouterGuard };
