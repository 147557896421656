/* eslint-disable camelcase */
import { getStructuresApi } from "@/services/api";
import { IStructures } from "@/types/dept-tutorials";

interface IFlatData {
  key: React.Key;
  title: string;
  parentKey: string;
}

let structuresData: IStructures[] = [];

let structuresDataFlat: IFlatData[] = [];

const getStructuresData = (
  onSuccess?: (data: IStructures[]) => void,
  onError?: () => void
) => {
  getStructuresApi()
    .then((res) => {
      const { data } = res;

      structuresData = data;
      structuresDataFlat = getStructuresDataFlat(data);
      onSuccess?.(data);
    })
    .catch(() => {
      onError?.();
    });
};

const findAllKeys = (structures: IStructures) => {
  const keys: string[] = [];

  function traverse(node: IStructures) {
    keys.push(node.key);
    if (node.children && node.children.length > 0) {
      node.children.forEach(traverse);
    }
  }

  traverse(structures);

  return keys;
};

const findNodeByKeys = (keys: string[]) => {
  const result: IStructures[] = [];

  const traverseFindNode = (nodeList: IStructures[]) => {
    for (let index = 0; index < nodeList.length; index++) {
      const node = nodeList[index];

      if (keys.includes(node.key)) {
        result.push(node);
      } else if (node.children && node.children.length) {
        traverseFindNode(node.children);
      }
      if (result.length === keys.length) {
        break;
      }
    }
  };

  traverseFindNode(structuresData);

  return result;
};

const getParentKey = (key: React.Key, tree: IStructures[]): React.Key => {
  let parentKey: React.Key = "";

  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];

    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else {
        const pKey = getParentKey(key, node.children);

        if (pKey) {
          parentKey = pKey;
        }
      }
    }
  }

  return parentKey;
};

// 将structuresData 转为扁平化
const getStructuresDataFlat = (data: IStructures[]) => {
  const result: IFlatData[] = [];

  const flattenData = (treeData: IStructures[]) => {
    for (let i = 0; i < treeData.length; i++) {
      const node = treeData[i];

      const { key, title, parent_id } = node;

      result.push({ key, title, parentKey: parent_id });
      if (node.children) {
        flattenData(node.children);
      }
    }
  };

  flattenData(data);

  return result;
};

export {
  findAllKeys,
  findNodeByKeys,
  getParentKey,
  getStructuresData,
  structuresData,
  structuresDataFlat,
};
