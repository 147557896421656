import { IPageParams, IPageResolve, IResolve } from "@/types/communal";
import { INotifications } from "@/types/notifications";

import { api } from "./api";

/**
 * 获取未读消息
 * @param param0
 * @returns
 */
export const getUnreadNotificationsApi = async (
  data?: IPageParams
): Promise<IPageResolve<INotifications[]>> => {
  return await api.get("/notifications/unread", {
    params: data,
  });
};

/**
 * 获取已读消息
 * @param param0
 * @returns
 */
export const getReadNotificationsApi = async (
  data?: IPageParams
): Promise<IPageResolve<INotifications[]>> => {
  return await api.get("/notifications/read", {
    params: data,
  });
};

/**
 * 设置消息为已读
 * @param param0
 * @returns
 */
export const putReadNotificationsApi = async (
  msgId: string
): Promise<IResolve<string[]>> => {
  return await api.put(`/notifications/read/${msgId}`);
};

export const putClearUnreadNotificationsApi = async (): Promise<
  IResolve<string[]>
> => {
  return await api.put("/notifications/unread/clear");
};
