import { FC } from "react";

import { NavItem } from "./nav-item";
import { INavListProps } from "./props";

export const NavList: FC<INavListProps> = (props) => {
  const { navList, onNavClick } = props;

  return (
    <div>
      {navList.map((item) => {
        if (item.isHidden) {
          return null;
        }

        return (
          <NavItem
            key={item.path}
            nav={item}
            active={props.activeKey === item.path}
            onClick={onNavClick}
          />
        );
      })}
    </div>
  );
};
