import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { LANGUAGE } from "@/libs/constants";
import { localGet } from "@/utils/storage";

import { enUS } from "./resources/en_US"; // 英语（美式）
import { zhCN } from "./resources/zh_CN"; // 简体中文
import { zhTW } from "./resources/zh_TW"; // 繁體中文(台灣)
import { esES } from "./resources/es_ES"; // 繁體中文(台灣)

const getLanguageByBrowser = () => {
  const language = Intl.DateTimeFormat().resolvedOptions().locale;

  if (language === "zh-TW") {
    return "zhTW";
  }
  if (language.includes("en")) {
    return "en";
  }
  if (language.includes("es")) {
    return "esES";
  }

  return "zhCN";
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enUS,
    },
    zhCN: {
      translation: zhCN,
    },
    zhTW: {
      translation: zhTW,
    },
    esES: {
      translation: esES,
    },
  },
  lng: localGet(LANGUAGE) || getLanguageByBrowser(),
  interpolation: {
    escapeValue: false,
  },
});
