import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const NoticeCenter = lazy(() => import("@/pages/notice-center"));

const noticeCenter: RouteObject = {
  path: "/notice-center",
  element: <RouterGuard element={<NoticeCenter />} />,
  children: [],
};

export default noticeCenter;
