import * as authTypes from "@/types/auth";
import { IResolve } from "@/types/communal";

import { api } from "./api";

export const authLoginReq = async (
  params: authTypes.ILogin
): Promise<authTypes.LoginResolveType> => {
  return await api.post(`/auth/login`, params);
};

export const authLogoutReq = async (): Promise<IResolve<[]>> => {
  return await api.post(`/auth/logout`);
};

export const getPermissionReq = async (): Promise<
  IResolve<authTypes.IPersonalPermission>
> => {
  return await api.get(`/personal/permissions`);
};

export const getAuthInfoReq = async (): Promise<
  IResolve<authTypes.IAuthInfo>
> => {
  return await api.get(`/auth/me`);
};

export const refreshTokenReq = async (): Promise<
  IResolve<authTypes.IRefreshToken>
> => {
  return await api.post(`/auth/refreshToken`);
};
