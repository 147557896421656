import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const Error = lazy(() => import("@/pages/error"));

export const error: RouteObject = {
  path: "*",
  element: <RouterGuard element={<Error />} />,
};
