/* eslint-disable react-hooks/exhaustive-deps */
import "./index.css";

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useDebounceFn } from "ahooks";
import { Button, Input, Modal, Tree } from "antd";
import { DataNode, TreeProps } from "antd/es/tree";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  findAllKeys,
  getParentKey,
  structuresData,
  structuresDataFlat,
} from "@/libs/structures";
import { IStructures } from "@/types/dept-tutorials";

import styles from "./index.module.less";

interface IPersonnelScopeModalProps {
  open: boolean;
  defaultCheckedNodes?: IStructures[];
  onOk: (node: IStructures[]) => void;
  onCancel: () => void;
}

export const PersonnelScopeModal: FC<IPersonnelScopeModalProps> = (props) => {
  const { open, defaultCheckedNodes = [], onCancel, onOk } = props;

  const [structures, setStructures] = useState<IStructures[]>(structuresData);

  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

  const [checkedStaff, setCheckedStaff] = useState<IStructures[]>([]);

  const [searchValue, setSearchValue] = useState("");

  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const selectedCount = useMemo(() => {
    let count = 0;

    const calcCount = (node: IStructures) => {
      if (node.type === 0) {
        count++;
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(calcCount);
      }
    };

    checkedStaff.forEach(calcCount);

    return count;
  }, [checkedStaff]);

  const { t } = useTranslation();

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    onOk(checkedStaff);
  };

  const onCheck: TreeProps["onCheck"] = (checkedKeys, info) => {
    setCheckedKeys(checkedKeys as string[]);
    const { checkedNodes } = info;

    const staffList: IStructures[] = [];

    checkedNodes.forEach((item) => {
      const node = item as IStructures;

      if (!(checkedKeys as string[]).includes(node.parent_id)) {
        staffList.push(node);
      }
    });
    setCheckedStaff(staffList);
  };

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const handleClearAllKeys = () => {
    setCheckedKeys([]);
    setCheckedStaff([]);
  };

  const handleRemoveKey = (node: IStructures) => {
    const removeKeys = findAllKeys(node);

    setCheckedKeys(checkedKeys.filter((key) => !removeKeys.includes(key)));

    setCheckedStaff(checkedStaff.filter((item) => item.key !== node.key));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value) {
      const newExpandedKeys = structuresDataFlat
        .map((item) => {
          if (
            item.title.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >
            -1
          ) {
            return getParentKey(item.key, structuresData);
          }

          return null;
        })
        .filter((item, i, self) => {
          return item && self.indexOf(item) === i;
        });

      setExpandedKeys(newExpandedKeys as React.Key[]);
    } else {
      setExpandedKeys([]);
    }
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const { run: handleSearch } = useDebounceFn(onSearchChange, {
    wait: 500,
  });

  useEffect(() => {
    if (open) {
      setStructures(structuresData);
      if (defaultCheckedNodes.length) {
        setCheckedKeys(defaultCheckedNodes.map((item) => item.key));
        setCheckedStaff(defaultCheckedNodes);
      }
    } else {
      setCheckedKeys([]);
      setCheckedStaff([]);
    }
  }, [open, defaultCheckedNodes]);

  const treeData = useMemo(() => {
    const loop = (data: IStructures[]): DataNode[] =>
      data.map((item) => {
        const strTitle = item.title as string;

        const index = strTitle
          .toLocaleLowerCase()
          .indexOf(searchValue.toLocaleLowerCase());

        const beforeStr = strTitle.substring(0, index);

        const valueStr = strTitle.substring(index, index + searchValue.length);

        const afterStr = strTitle.slice(index + searchValue.length);

        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{valueStr}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );

        if (item.children) {
          return { ...item, title, children: loop(item.children) };
        }

        return {
          ...item,
          title,
        };
      });

    return loop(structuresData);
  }, [searchValue, structures]);

  return (
    <Modal
      width={672}
      open={open}
      maskClosable={false}
      keyboard={false}
      footer={null}
      className="personnel-scope-modal"
      closable={false}
      zIndex={1001}
    >
      <div className={styles["personnel-scope-modal-body"]}>
        <Input
          suffix={<SearchOutlined />}
          className={styles["searchInput"]}
          onChange={handleSearch}
        />
        <div className={styles["user-selector-wrapper"]}>
          <div className={styles["user-selector-left"]}>
            <div className={styles["user-selector-header"]}>
              <span className={styles["title"]}>
                {t("organizational_structure")}
              </span>
            </div>
            <div className={styles["user-selector-body"]}>
              <Tree
                checkable
                defaultExpandAll={true}
                checkedKeys={checkedKeys}
                onCheck={onCheck}
                treeData={treeData}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
              />
            </div>
          </div>
          <div className={styles["user-selector-right"]}>
            <div className={styles["user-selector-header"]}>
              <span className={styles["title"]}>
                {t("selected_person")}：{selectedCount}
              </span>
              <span
                className={styles["clear-btn"]}
                onClick={handleClearAllKeys}
              >
                {t("clear")}
              </span>
            </div>
            <ul className={styles["user-selector-body"]}>
              {checkedStaff.map((item) => {
                return (
                  <li key={item.key} className={styles["user-selected-item"]}>
                    <span className={styles["user-name"]}>{item.title}</span>
                    <CloseOutlined onClick={() => handleRemoveKey(item)} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles["modal-footer"]}>
        <Button className={styles["cancel-btn"]} onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button className={styles["ok-btn"]} onClick={handleOk}>
          {t("confirm")}
        </Button>
      </div>
    </Modal>
  );
};
