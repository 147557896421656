import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";

const MyCourse = lazy(() => import("@/pages/my-course"));

const CourseViewHistory = lazy(() => import("@/pages/course-viewing-history"));

const myCourse: RouteObject = {
  path: "/my-course",
  children: [
    {
      index: true,
      element: <RouterGuard element={<MyCourse />} />,
    },
    {
      path: "/my-course/course-view-history",
      element: <RouterGuard element={<CourseViewHistory />} />,
    },
  ],
};

export default myCourse;
