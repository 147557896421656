import { PlusOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { CreateTutorial } from "@/components/create-tutorial/create-tutorial";

import styles from "./quick-generate-tutorial.module.less";

export const QuickGenerateTutorial: FC = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className={styles["generate-tutorial-btn"]}
        onClick={handleOpenModal}
      >
        <PlusOutlined
          style={{ color: "#fff", fontSize: "12px", verticalAlign: "middle" }}
        />
        <span className={styles["txt"]}>{t("quick_generate_tutorial")}</span>
      </div>
      <CreateTutorial open={isModalOpen} onClose={handleClose} />
    </>
  );
};
