import { RouteObject } from "react-router-dom";

import { error } from "./error";
import { index } from "./index/index";
import { login } from "./login";
import { reviewTestPaper } from "./review-test-paper";

const routes: RouteObject[] = [index, login, error, reviewTestPaper];

export default routes;
