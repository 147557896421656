import { Dropdown } from "antd";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../icon";
import styles from "./header.module.less";
import { useUserModalStore } from "./use-store";

export const UserInfoModal: FC = () => {
  const { staff, handleLogout } = useUserModalStore();

  const { t } = useTranslation();

  const dropdownRender = useCallback(() => {
    return (
      <div className={styles["headPortraitModal"]}>
        <i
          className={styles["img"]}
          style={{ backgroundImage: 'url("/img/logo.png")' }}
        />
        <p className={styles["userName"]}>{staff?.user_name}</p>
        <p className={styles["info"]}>
          {t("department")}: {staff?.department_name}
        </p>
        <p className={styles["info"]}>
          {t("position")}: {staff?.position_name}
        </p>
        <hr className={styles["hr"]} />
        <div className={styles["logOutBtn"]} onClick={handleLogout}>
          <span style={{ verticalAlign: "middle" }}>{t("logout")}</span>
          <Icon icon={"logOut"} style={{ verticalAlign: "middle" }} />
        </div>
      </div>
    );
  }, [staff, t, handleLogout]);

  return (
    <>
      {staff ? (
        <Dropdown dropdownRender={dropdownRender} placement="bottom">
          <span className={styles["headPortrait"]}>
            <i style={{ backgroundImage: 'url("/img/logo.png")' }} />
          </span>
        </Dropdown>
      ) : null}
    </>
  );
};
