import { FC } from "react";

import styles from "./navigation.module.less";
import { INavItemProps } from "./props";

export const NavItem: FC<INavItemProps> = (props) => {
  const { nav, active, onClick } = props;

  const handleClick = () => {
    onClick?.(nav);
  };

  return (
    <span onClick={handleClick} className={styles["nav-item"]}>
      {active ? nav.activeIcon : nav.icon}
      <b className={`${styles["nav-label"]} ${active ? styles["active"] : ""}`}>
        {nav.label}
      </b>
    </span>
  );
};
