import { Badge, Layout } from "antd";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Env } from "@/config/env";
import { TOKEN_EXPIRE } from "@/libs/constants";
import { userPermissionsState } from "@/models";
import { localGet } from "@/utils/storage";

import { Icon } from "../icon";
import styles from "./header.module.less";
import { LanguageSwitching } from "./language-switching";
import { useUserModalStore } from "./use-store";
import { UserInfoModal } from "./user-info-modal";

export const Header: FC = () => {
  const { Header: AntHeader } = Layout;

  const userPermissions = useRecoilValue(userPermissionsState);

  const navigate = useNavigate();

  const handleGoBackstage = () => {
    const tokenExpire = localGet(TOKEN_EXPIRE);

    const time = tokenExpire - new Date().valueOf();

    if (time < 1000) {
      navigate("/login");

      return;
    }

    window.open(Env.BACKSTAGE_URL);
  };

  const { isUnreadMessage } = useUserModalStore();

  const { pathname } = useLocation();

  return (
    <AntHeader className={styles.header}>
      <div
        className={`${styles.contentWrapper} ${pathname === "/login" ? styles.login : ""
          }`}
      >
        <h1 className={styles.logo} title="FLEXIVID" onClick={() => navigate("/")} />
        <div className={styles.rightContent}>
          <LanguageSwitching />
          {userPermissions?.is_admin_user && (
            <div
              className={styles["margin-right-24"]}
              onClick={handleGoBackstage}
            >
              <Icon
                icon="backstage"
                style={{ cursor: "pointer", userSelect: "none" }}
              />
            </div>
          )}
          <div className={styles["margin-right-24"]}>
            <Badge dot={isUnreadMessage} offset={[-3, 0]}>
              <Icon icon="bell" onClick={() => navigate("/notice-center")} />
            </Badge>
          </div>
          <UserInfoModal />
        </div>
      </div>
    </AntHeader>
  );
};
