/* eslint-disable react-hooks/exhaustive-deps */
import "./modal.css";

import { CloseOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Modal } from "antd";
import dayjs from "dayjs";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { findNodeByKeys } from "@/libs/structures";

import { CoursewareUpload } from "./courseware-upload";
import styles from "./create-tutorial.module.less";
import { PersonnelScopeInput } from "./personnel-scope-input";
import { IGenerateTutorialProps } from "./types";
import { useStore } from "./use-store";

export const CreateTutorial: FC<IGenerateTutorialProps> = (props) => {
  const { open, onClose, defaultData } = props;

  const { t } = useTranslation();

  const { TextArea } = Input;

  const { RangePicker } = DatePicker;

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const { form, loading, handleOk } = useStore(props);

  useEffect(() => {
    if (defaultData) {
      const { title, description, personnelScope, shelfTime, courseware } =
        defaultData;

      form.setFieldsValue({
        title,
        description,
        personnelScope: findNodeByKeys(personnelScope),
        shelfTime: shelfTime.map((item) => dayjs(item)),
        courseware: courseware.map((item) => item),
      });
    }
  }, [defaultData, open]);

  return (
    <>
      <Modal
        width={786}
        open={open}
        maskClosable={false}
        keyboard={false}
        footer={null}
        className="quick-generate-tutorial-modal"
        closable={false}
      >
        <div className={styles["modal-header"]}>
          <span>
            {defaultData?.id
              ? t("update_tutorial")
              : t("quick_generate_tutorial")}
          </span>
          <CloseOutlined onClick={handleCancel} />
        </div>
        <Form
          form={form}
          className={styles["modal-body"]}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          autoComplete="off"
        >
          <Form.Item
            label={t("name")}
            name="title"
            rules={[{ required: true }]}
          >
            <Input style={{ width: 400 }} />
          </Form.Item>
          <Form.Item
            label={t("description")}
            name="description"
            rules={[{ required: true }]}
          >
            <TextArea rows={4} style={{ width: 400 }} />
          </Form.Item>
          <Form.Item
            label={t("personnel_scope")}
            name="personnelScope"
            rules={[{ required: true, type: "array" }]}
          >
            <PersonnelScopeInput />
          </Form.Item>
          <Form.Item
            label={t("shelf_time")}
            name="shelfTime"
            rules={[{ required: true }]}
          >
            <RangePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              style={{ width: 400 }}
            />
          </Form.Item>
          <Form.Item
            label={t("tutorials")}
            name="courseware"
            rules={[{ required: true, type: "array" }]}
          >
            <CoursewareUpload />
          </Form.Item>
        </Form>
        <div className={styles["modal-footer"]}>
          <Button className={styles["cancel-btn"]} onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button
            className={styles["ok-btn"]}
            onClick={handleOk}
            loading={loading}
          >
            {t("confirm")}
          </Button>
        </div>
      </Modal>
    </>
  );
};
