import "./i18n";

import message from "antd/es/message";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./app";

dayjs.extend(weekday);
dayjs.extend(localeData);

const container = document.getElementById("root");

message.config({
  top: 16,
});

if (container) {
  const root = ReactDOM.createRoot(container);

  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
