import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { RouterGuard } from "../guard";
const MoreCourse = lazy(() => import("@/pages/more-course"));

const moreCourse: RouteObject = {
  path: "/more-courses",
  element: <RouterGuard element={<MoreCourse />} />,
  children: [],
};

export default moreCourse;
