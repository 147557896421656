import { AxiosRequestConfig } from "axios";

import { IResolve } from "@/types/communal";
import { IOSSUploadRequest, IUploadResult } from "@/types/oss";

import { api } from "./api";

/**
 * 上传图片、文件
 * @returns
 */
export const uploadFileApi = async (
  data: IOSSUploadRequest,
  config?: AxiosRequestConfig
): Promise<IResolve<IUploadResult>> => {
  const formData = new FormData();

  formData.append("file", data.file);

  return await api.post("/oss/uploadFile", formData, config);
};
