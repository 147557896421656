import { IEnv } from "./env.type";

let DOMAIN = "";

switch (window.location.hostname) {
  case "test-new-lms.wiltechs.com":
    DOMAIN = ".wiltechs.com";
    break;
  case "w3lms.com":
    DOMAIN = ".w3lms.com";
    break;
  case "www.w3lms.com":
    DOMAIN = ".w3lms.com";
    break;
  case "test.flexivid.com":
    DOMAIN = ".flexivid.com";
    break;
  case "flexivid.com":
    DOMAIN = ".flexivid.com";
    break;
  case "flexivid.cn":
    DOMAIN = ".flexivid.cn";
    break;
  case "flexivid.net":
    DOMAIN = ".flexivid.net";
    break;
  case "www.flexivid.com":
    DOMAIN = ".flexivid.com";
    break;
  case "www.flexivid.cn":
    DOMAIN = ".flexivid.cn";
    break;
  case "www.flexivid.net":
    DOMAIN = ".flexivid.net";
    break;
}

const devConfig = {
  API_URL: "https://apitest.flexivid.com",
  ECHO_HOST: "https://echotest.flexivid.com",
  BACKSTAGE_URL: "https://admintest.flexivid.com",
  DOMAIN,
};

const testConfig = {
  API_URL: "https://apitest.flexivid.com",
  ECHO_HOST: "https://echotest.flexivid.com",
  BACKSTAGE_URL: "https://admintest.flexivid.com",
  DOMAIN,
};

const prdConfig = {
  API_URL: "https://api.flexivid.com",
  ECHO_HOST: "https://echo.flexivid.com",
  BACKSTAGE_URL: "https://admin.flexivid.com",
  DOMAIN,
};

const getEnvConfig = (environment: string) => {
  if (environment === "test") {
    return testConfig;
  }
  if (environment === "prd") {
    return prdConfig;
  }

  return devConfig;
};

export const Env: IEnv = getEnvConfig((window as any).environment);
