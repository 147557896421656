import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { RouterGuard } from "../guard";

const EchoAvatarCreate = lazy(() => import("@/pages/echo-avatar/echo-avatar-create"));

const EchoAvatarCreating = lazy(() => import("@/pages/echo-avatar/echo-avatar-creating"));

const echoAvatar: RouteObject = {
    path: "/echo-avatar",
    children: [
        {
            path: "/echo-avatar/creating",
            element: <RouterGuard element={<EchoAvatarCreating />} />
        },
        {
            path: "/echo-avatar/create/:tutorialId",
            element: <RouterGuard element={<EchoAvatarCreate />} />
        }
    ]
}

export default echoAvatar;