import { IResolve } from "@/types/communal";
import {
  DeptTutorialsDetailResolveType,
  DeptTutorialsResolveType,
  EALanguagesInfoResolveType,
  ICreateTutorialEARequest,
  ICreateTutorialRequest,
  ICreateTutorialResult,
  IDeptTutorialRequest,
  IDetectLanguage,
  IEALanguageInfo,
  IEditTutorialRequest,
  IEditTutorialResult,
  IIPostedTutorialListRequest,
  IStructures,
  IStructuresRequest,
  IStudentTutorialLearningHistory,
  ITutorialLearningHistoryRequest,
  StudentLearningHistoryType,
  TutorialHistoryListType,
  TutorialTimbreResolveType,
} from "@/types/dept-tutorials";

import { api, baseURL } from "./api";
import { ap } from "ramda";

/**
 * 获取部门教程详情
 * @param id 教程id
 * @returns
 */
export const getDeptTutorialsDetailApi = async (
  id: string
): Promise<DeptTutorialsDetailResolveType> => {
  return await api.get(`/personal/tutorials/${id}`);
};

/**
 * 获取我发布的部门教程详情
 * @param id 教程id
 * @returns
 */
export const getDeptTutorialsDetailByCreationApi = async (
  id: string
): Promise<DeptTutorialsDetailResolveType> => {
  return await api.get(`/personal/tutorials/creation/${id}`);
};

/**
 * 获取部门教程列表
 * @returns
 */
export const getDeptTutorialsListApi = async (
  params: IDeptTutorialRequest
): Promise<DeptTutorialsResolveType> => {
  return await api.get(`/personal/tutorials`, { params });
};

/**
 * 我發佈的
 * @returns
 */
export const getIPostedTutorialListApi = async (
  params: IIPostedTutorialListRequest
): Promise<DeptTutorialsResolveType> => {
  return await api.get(`/personal/tutorials/creation`, { params });
};

/**
 * 删除教程
 * @param tutorialId 教程ID
 */
export const deleteMyPostedTutorialApi = async (tutorialId: string) => {
  return await api.delete(`/personal/tutorials/${tutorialId}`);
};

/**
 * 教程的观看记录
 */
export const getTutorialLearningHistoryApi = async (
  params: ITutorialLearningHistoryRequest
): Promise<TutorialHistoryListType> => {
  return await api.get("/personal/tutorials/learning/records", { params });
};
/**
 * 新建教程
 * @returns
 */
export const postCreateTutorialApi = async (
  data: ICreateTutorialRequest
): Promise<IResolve<ICreateTutorialResult[]>> => {
  return await api.post(`/personal/tutorials`, data);
};

/**
 * 更新教程
 * @returns
 */
export const putEditTutorialApi = async (
  id: string,
  data: IEditTutorialRequest
): Promise<IResolve<IEditTutorialResult[]>> => {
  return await api.put(`/personal/tutorials/${id}`, data);
};

/**
 * 人员架构
 * @returns
 */
export const getStructuresApi = async (
  params?: IStructuresRequest
): Promise<IResolve<IStructures[]>> => {
  return await api.get(`/structures`, {
    params,
  });
};

export const getStudentLearningHistoryListApi = async (
  tutorialId: string,
  params: ITutorialLearningHistoryRequest
): Promise<StudentLearningHistoryType> => {
  return await api.get(`/personal/tutorials/${tutorialId}/learning/records`, {
    params,
  });
};

export const getEALanguageInfoApi = async (
  tutorialId: string
): Promise<EALanguagesInfoResolveType> => {
  return await api.get(`/personal/tutorials/${tutorialId}/languages/info`);
};

/**
 * 教程-下载讲稿模板地址
 */
export const EASpeechTemplateLink = baseURL + '/personal/tutorials/ea/speech/template';

/**
 * 下载单个语种教程 链接
 * @param tutorialId
 * @param timbreId
 * @returns
 */
export const getTutorialTimbreLink = (
  tutorialId: string,
  timbreId: string
) => {
  return baseURL + `/personal/tutorials/${tutorialId}/timbre/${timbreId}/download`;
};

/**
 * (获取)确认教程信息多语种列表接口
 * @param tutorialId
 * @returns
 */
export const getTutorialTimbresApi = async (
  tutorialId: string
): Promise<TutorialTimbreResolveType> => {
  return await api.get(`/personal/tutorials/${tutorialId}/timbre`);
};

/**
 * 删除单个语种音色教程
 * @param timbreId
 * @returns
 */
export const deleteTutorialTimbreApi = async (
  timbreId: string
): Promise<IResolve<string[]>> => {
  return await api.delete(`/personal/tutorials/timbre/${timbreId}`);
};

/**
 * 批量下载语种音色教程(链接)
 * @param tutorialId
 * @returns
 */
export const getBatchDownloadTimbreLink = (tutorialId: string) => {
  return baseURL + `/personal/tutorials/${tutorialId}/timbre/batch/download`;
}

/**
 * 音色试听
 * @param languageType 语种类型
 * @param timbreId 音色ID
 * @returns
 */
export const getTimbreDemoApi = async (
  languageType: number,
  timbreId: number
): Promise<IResolve<{url: string}>> => {
  return await api.get(`/languages/${languageType}/timbres/${timbreId}/test`);
};

/**
 * 检测语种
 * @param tutorialId 
 * @returns 
 */
export const detectLanguageApi = async (
  tutorialId: string
): Promise<IResolve<IDetectLanguage>> => {
  return await api.get(`/personal/tutorials/${tutorialId}/asr/detect`);
}

export const createTutorialEaApi = async (tutorialId: string, createEaReq: ICreateTutorialEARequest): Promise<IResolve> => {
  const form  = new FormData();
  createEaReq.voices.forEach((item, i) => {
    for (const [k, v] of Object.entries(item)) {
      form.append(`voices[${i}][${k}]`, v + "");
    }
  });
  if (createEaReq.srt_file) {
    form.append("srt_file", createEaReq.srt_file);
  }
  return await api.post(`/personal/tutorials/${tutorialId}/eaGenerate`, form, {headers: {"Content-Type": "multipart/form-data"}})
}